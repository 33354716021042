export const SET_PROJECTS = "SET_PROJECTS";
export const SET_ORGANISATIONS = "SET_ORGANISATIONS";
export const SET_USERS = "SET_USERS";
export const SET_IS_FETCHING = "SET_IS_FETCHING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_BIBLE_LANGUAGES = "SET_BIBLE_LANGUAGES";
export const SET_ALL_LANGUAGES = "SET_ALL_LANGUAGES";
export const SET_ASSIGNED_USERS = "SET_ASSIGNED_USERS";
export const SET_USER_BOOKS = "SET_USER_BOOKS";
export const SET_USER_PROJECTS = "SET_USER_PROJECTS";
export const SET_SELECTED_BOOK = "SET_SELECTED_BOOK";
export const SET_TOKEN_LIST = "SET_TOKEN_LIST";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const SET_SELECTED_TOKEN = "SET_SELECTED_TOKEN";
export const SET_CONCORDANCE = "SET_CONCORDANCE";
export const SET_REFERENCE_NUMBER = "SET_REFERENCE_NUMBER";
export const CLEAR_STATE = "CLEAR_STATE";
export const SET_TRANSLATED_WORD = "SET_TRANSLATED_WORD";
export const SET_SOURCE_BOOKS = "SET_SOURCE_BOOKS";
export const SET_UPLOAD_ERROR_BOOKS = "SET_UPLOAD_ERROR_BOOKS";
export const COMPLETED_UPLOAD = "COMPLETED_UPLOAD";
export const CLEAR_UPLOAD_ERROR_BOOKS = "CLEAR_UPLOAD_ERROR_BOOKS";
